import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import {
  BasicButton,
  Button,
  ErrorMessage,
  LoadingIndicator,
  Modal,
  downloadBase64String,
  formatNumber,
} from '../../../common';
import {
  OWNER_ROLE_PERMISSIONS,
  PARTNER_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../../global/auth';
import { useAuth } from '../../../global/auth/newAuthProvider';
import theme from '../../../global/style/theme';
import {
  GET_ORGANIZATIONS_WITH_ZONES_THAT_HAVE_A_RENEWABLE_SUBSCRIPTION,
  GET_PARTNER_COMMISSION_INVOICE_PDF,
  GET_PARTNER_COMMISSION_NOTIFICATIONS,
} from './api';

const DrawerContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.white};
  overflow-y: auto;
  position: absolute;
  bottom: 0;
  left: ${({ $isOpen }) => ($isOpen ? 0 : '-100%')};
  z-index: 105;
  transition: left 0.2s ease-in-out;
  display: grid;
  grid-auto-rows: min-content;
  padding: 2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    height: calc(100% - 8.8rem);
  }
`;

const PopupContainer = styled(Modal)``;

const Container = ({ children, isOpen, isPopup }) => {
  if (isPopup) {
    return <PopupContainer hideHeader isOpen={isOpen} footer={<></>}>{children}</PopupContainer>;
  }
  return <DrawerContainer $isOpen={isOpen}>{children}</DrawerContainer>;
};

const Header = styled.header`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  gap: 0.8rem;
  padding: 0.5rem 0 2rem 0;
`;

const AlertCount = styled.div`
  min-width: 3.2rem;
  height: 3.2rem;
  border: 2px solid ${({ theme }) => theme.sweetOrange};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
`;

const Title = styled.h3`
  font-size: 1.6rem;
  font-weight: 500;
`;

const Text = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.3;

  & > span {
    font-weight: 600;
    padding-left: 0.4rem;
  }
`;

const Notification = styled.div`
  display: grid;
  gap: 1rem;
`;

const Organization = styled.div`
  display: grid;
  padding-top: 1rem;
  gap: 0.6rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.dark100};
  margin-bottom: 2rem;
`;

const NotificationsButton = styled(BasicButton)`
  height: 3.2rem;
  width: 3.2rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-bottom: 1rem;
`;

const NotificationsDrawer = ({ isPopup, isOpen, onClose, setHasNotifications }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const isOwner = hasAllRequiredPermissions(user, OWNER_ROLE_PERMISSIONS);
  const isPartner = hasAllRequiredPermissions(user, PARTNER_ROLE_PERMISSIONS);

  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);

  const {
    loading: renewableSubscriptionsLoading,
    data: renewableSubscriptionsData,
    error: renewableSubscriptionsError,
  } = useQuery(
    GET_ORGANIZATIONS_WITH_ZONES_THAT_HAVE_A_RENEWABLE_SUBSCRIPTION,
    {
      skip: !isOwner,
      // We cannot extend a subscription that is available for renewal
      // without leaving the application so this can definitely be cached
      fetchPolicy: 'cache-first',
    }
  );

  const {
    loading: partnerCommissionNotificationsLoading,
    data: partnerCommissionNotificationsdata,
    error: partnerCommissionNotificationsError,
  } = useQuery(GET_PARTNER_COMMISSION_NOTIFICATIONS, {
    skip: !isPartner,
    fetchPolicy: 'cache-first',
  });

  const commissionNotification =
    partnerCommissionNotificationsdata?.partnerCommissionNotifications?.[0];

  const [getInvoicePdf, { loading, error }] = useLazyQuery(
    GET_PARTNER_COMMISSION_INVOICE_PDF,
    {
      onCompleted: ({ partnerCommissionInvoicePdf }) => {
        setIsDownloadSuccess(true);
        const fileName = `${commissionNotification.number}.pdf`
          .split('/')
          .join('_');
        downloadBase64String(partnerCommissionInvoicePdf, fileName);
      },
      onError: () => {
        setIsDownloadSuccess(false);
      },
    }
  );

  const totalSubscriptionsCount = useMemo(
    () =>
      renewableSubscriptionsData?.organizations?.reduce(
        (acc, curr) => acc + curr.zonesWithARenewableSubscription.length,
        0
      ) ?? 0,
    [renewableSubscriptionsData?.organizations]
  );

  const totalCommissionNotificationsCount = useMemo(
    () =>
      partnerCommissionNotificationsdata?.partnerCommissionNotifications
        .length ?? 0,
    [partnerCommissionNotificationsdata?.partnerCommissionNotifications]
  );

  useEffect(() => {
    if (totalSubscriptionsCount > 0 || totalCommissionNotificationsCount > 0) {
      setHasNotifications(true);
    }
  }, [
    totalSubscriptionsCount,
    totalCommissionNotificationsCount,
    setHasNotifications,
  ]);

  const handleExtent = useCallback(
    (organizationId) => {
      const targetRoute = `/organizations/${organizationId}/renew-subscriptions`;
      const isCurrentRoute =
        targetRoute ===
        location.pathname;
      if (isCurrentRoute) {
        onClose();
      } else {
        navigate(targetRoute);
        onClose();
      }
    },
    [navigate, onClose, location]
  );

  const endingFreeTrials = useMemo(() => {
    return renewableSubscriptionsData?.organizations
      ?.map((organization) =>
        organization.zonesWithEndingFreeTrail?.map((zone) => ({
          ...zone,
          organizationId: organization.id,
        }))
      )
      .flat();
  }, [renewableSubscriptionsData?.organizations]);

  const handleExtendFreeTrail = useCallback(
    (locationId, zoneId, organizationId) => {
      const targetRoute = `organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/0`;

      if (location.pathname.includes(targetRoute)) {
        onClose();
        return;
      }

      navigate(targetRoute);
      onClose();
    },
    [location.pathname, navigate, onClose]
  );

  const isLoading =
    renewableSubscriptionsLoading || partnerCommissionNotificationsLoading;

  return (
    <Container isOpen={isOpen} isPopup={isPopup}>
      {isLoading ? (
        <StyledLoadingIndicator />
      ) : (
        <>
          <Header>
            <AlertCount>
              <Label>
                {totalSubscriptionsCount +
                  totalCommissionNotificationsCount +
                  Number(endingFreeTrials?.length)}
              </Label>
            </AlertCount>
            <Label>{t('global:notifications.title')}</Label>
            <NotificationsButton
              onClick={onClose}
              title={t('common:button.title.closeNotificationsDrawer')}
            >
              <CloseIcon width="1.4rem" height="1.4rem" color={theme.dark200} />
            </NotificationsButton>
          </Header>
          {isOwner &&
            endingFreeTrials?.length > 0 &&
            endingFreeTrials.map(({ locationId, id, organizationId }) => (
              <Fragment key={id}>
                <Divider />
                <Notification style={{ marginBottom: 16 }}>
                  <Title>{t('global:notification.free.trial.ending')}</Title>
                  <Button
                    highlightGradient="sunset"
                    size="medium"
                    onClick={() =>
                      handleExtendFreeTrail(locationId, id, organizationId)
                    }
                  >
                    {t('common:button.extendNow')}
                  </Button>
                </Notification>
              </Fragment>
            ))}

          {isOwner && totalSubscriptionsCount > 0 ? (
            <>
              <Divider />
              {renewableSubscriptionsError ? (
                <ErrorMessage error={renewableSubscriptionsError} />
              ) : (
                <Notification>
                  <Title>{t('global:notifications.needsExtension')}</Title>
                  <Text>
                    {t(
                      `global:notifications.${
                        totalSubscriptionsCount > 1
                          ? 'subscriptionsExtensionWithCountPlural'
                          : 'subscriptionsExtensionWithCount'
                      }`,
                      {
                        count: totalSubscriptionsCount,
                      }
                    )}
                  </Text>
                  {renewableSubscriptionsData?.organizations?.map(
                    (organization) =>
                      organization.zonesWithARenewableSubscription.length >
                      0 ? (
                        <Organization key={organization.id}>
                          <Text>
                            {organization.name}{' '}
                            <span>
                              (
                              {
                                organization.zonesWithARenewableSubscription.length
                              }
                              )
                            </span>
                          </Text>
                          <Button
                            highlightGradient="sunset"
                            size="medium"
                            onClick={() => handleExtent(organization.id)}
                            title={t('common:button.title.extendSubscription')}
                          >
                            {t('common:button.extendNow')}
                          </Button>
                        </Organization>
                      ) : null
                  )}
                </Notification>
              )}
            </>
          ) : null}
          {isPartner && totalCommissionNotificationsCount > 0 ? (
            <>
              <Divider />
              {partnerCommissionNotificationsError ? (
                <ErrorMessage error={partnerCommissionNotificationsError} />
              ) : (
                <Notification>
                  <Title>{commissionNotification.organization.name}</Title>
                  <Text>
                    {t('global:notifications.commissionRequest.description', {
                      value: formatNumber(
                        commissionNotification.totalCommission
                      ),
                    })}
                  </Text>
                  <Button
                    highlightGradient="sunset"
                    size="medium"
                    isFail={error}
                    isLoading={loading}
                    isSuccess={isDownloadSuccess}
                    onClick={() =>
                      getInvoicePdf({
                        variables: {
                          partnerCommissionInvoiceId: Number(
                            commissionNotification.id
                          ),
                        },
                      })
                    }
                    title={t(
                      'global:notifications.commissionRequest.downloadTitle'
                    )}
                  >
                    {t('global:notifications.commissionRequest.downloadBtn')}
                  </Button>
                </Notification>
              )}
            </>
          ) : null}
        </>
      )}
    </Container>
  );
};

export default NotificationsDrawer;
